<p-fileUpload
  #fileUpload
  mode="advanced"
  name="workflowFile"
  [url]="url"
  accept="application/json"
  (onUpload)="onUpload($event)"
  (onBeforeUpload)="prepareRequest($event.formData)"
  (onError)="onError()"
>
  <ng-template let-file pTemplate="file">
    <div class="p-fileupload-row">
      <div class="p-fileupload-filename">{{ file.name }}</div>
      <div>{{ fileUpload.formatSize(file.size) }}</div>
    </div>
  </ng-template>

  <ng-template pTemplate="content">
    <ng-container *ngIf="uploadStatuses.length">
      <p-messages *ngFor="let item of uploadStatuses" [severity]="item.severity">
        <ng-template pTemplate>
          <div>
            <p>
              <i *ngIf="item.severity === 'success'" class="pi pi-check mr-2"></i>
              <i *ngIf="item.severity === 'error'" class="pi pi-times-circle mr-2"></i>
              <span class="font-bold">{{ item.summary }}</span>
            </p>

            <selfai-platform-spoiler [text]="item.detail" [length]="200"></selfai-platform-spoiler>
          </div>
        </ng-template>
      </p-messages>
    </ng-container>
  </ng-template>
</p-fileUpload>

<div class="mt-3">
  <p-checkbox
    [binary]="true"
    label="{{ 'workflow-list.dialog.import-data-source' | translate }}"
    [(ngModel)]="importDataSources"
    (ngModelChange)="changeImportingDatasourceCheckbox()"
  ></p-checkbox>
</div>

<div class="mt-3">
  <p-checkbox
    [binary]="true"
    label="{{ 'workflow-list.dialog.data-source-overwriting' | translate }}"
    [disabled]="!importDataSources"
    [(ngModel)]="overrideDataSources"
  ></p-checkbox>
</div>
