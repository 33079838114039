export enum PipelinePermission {
  WorkflowList = 'workflows:list',
  WorkflowCreate = 'workflows:create',
  WorkflowUpload = 'workflows:upload',

  WorkflowCloneOwn = 'workflows:clone:own',
  WorkflowDeleteOwn = 'workflows:delete:own',
  WorkflowExecuteOwn = 'workflows:execute:own',
  WorkflowRunOwn = 'workflows:run:own',
  WorkflowGetOwn = 'workflows:get:own',
  WorkflowStopOwn = 'workflows:stop:own',
  WorkflowUpdateOwn = 'workflows:update:own',
  WorkflowExportOwn = 'workflows:export:own',

  WorkflowCloneAny = 'workflows:clone:any',
  WorkflowDeleteAny = 'workflows:delete:any',
  WorkflowExecuteAny = 'workflows:execute:any',
  WorkflowGetAny = 'workflows:get:any',
  WorkflowStopAny = 'workflows:stop:any',
  WorkflowUpdateAny = 'workflows:update:any',
  WorkflowRunAny = 'workflows:run:any',
  WorkflowExportAny = 'workflows:export:any',

  DatasourcesListAny = 'datasources:list:short',
  DatasourcesCreate = 'datasources:create',
  DatasourcesGetAny = 'datasources:get:any',
  DatasourcesUpdateAny = 'datasources:update:any',
  DatasourcesDeleteAny = 'datasources:delete:any',

  DatasourcesGetOwn = 'datasources:get:own',
  DatasourcesUpdateOwn = 'datasources:update:own',
  DatasourcesDeleteOwn = 'datasources:delete:own',

  UserFunctionsListAny = 'functions:list',
  UserFunctionsCreate = 'functions:create',
  UserFunctionsUpload = 'functions:upload',
  UserFunctionsGetAny = 'functions:get:any',
  UserFunctionsUpdateAny = 'functions:update:any',
  UserFunctionsDeleteAny = 'functions:delete:any',
  UserFunctionsCloneAny = 'functions:clone',
  UserFunctionsExportAny = 'functions:export',

  UserFunctionsGetOwn = 'functions:get:own',
  UserFunctionsDeleteOwn = 'functions:delete:own',
  UserFunctionsUpdateOwn = 'functions:update:own',

  SchedulesListAny = 'scheduling:list',
  SchedulesCreate = 'scheduling:create',
  SchedulesGetAny = 'scheduling:get:any',
  SchedulesUpdateAny = 'scheduling:update:any',
  SchedulesDeleteAny = 'scheduling:delete:any',

  PresetsList = 'presets:list',
  PresetsCreate = 'presets:create',
  PresetsGetAny = 'presets:get:any',
  PresetsUpdateAny = 'presets:update:any',
  PresetsDeleteAny = 'presets:delete:any',

  WorkflowPresetSetAny = 'workflows:preset-set:any',
  WorkflowPresetSetOwn = 'workflows:preset-set:own',

  WorkflowSchedulingSetOwn = 'workflows:scheduling-set:own',
  WorkflowSchedulingSetAny = 'workflows:scheduling-set:any',
}

export const enum PipelinePermissionEntity {
  Datasources = 'datasources',
  Workflows = 'workflows',
  Workspaces = 'workspaces',
  Schedules = 'scheduling',
  Presets = 'presets',
  Functions = 'functions',
}

export const enum PipelinePermissionScope {
  Own = 'own',
  Any = 'any',
}
