import { Injectable } from '@angular/core';
import { Profile } from '@selfai-platform/pipeline-common';
import { PermissionService, PipelinePermission } from '@selfai-platform/shared';
import { map, Observable, of } from 'rxjs';
import { KeCurrentUserDomainService } from '../../current-user';

@Injectable({
  providedIn: 'root',
})
export class DataSourcePermissionService {
  private currentUser$ = this.keCurrentUserDomainService.getProfile();

  constructor(
    private readonly permissionService: PermissionService,
    private readonly keCurrentUserDomainService: KeCurrentUserDomainService,
  ) {
    this.keCurrentUserDomainService.loadProfileIfEmpty();
  }

  public hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getDatasourcePermissions().includes(permission);
  }

  public canCreateSource(): boolean {
    return this.hasCurrentPermission(PipelinePermission.DatasourcesCreate);
  }

  public canViewSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesGetAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesGetOwn)),
    );
  }

  public canUpdateSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateOwn)),
    );
  }

  public canDeleteSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteOwn)),
    );
  }

  private getDatasourcePermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('datasources'));
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return (<Observable<Profile>>this.currentUser$).pipe(map((data) => data.id === userId));
  }
}
