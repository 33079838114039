import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@selfai-platform/shared';
import { Message } from 'primeng/api';
import { FileUploadEvent } from 'primeng/fileupload';
import { WorkflowDatasourceStatuses } from '../../enums';
import { UploadWorkflowRequest } from '../../models';
import { WorkflowListComponentService } from '../../services';

@Component({
    selector: 'selfai-platform-workflow-upload-dialog',
    templateUrl: './workflow-upload-dialog.component.html',
    styleUrls: ['./workflow-upload-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WorkflowUploadDialogComponent implements OnInit {
  url!: string;

  importDataSources = false;
  overrideDataSources = false;

  uploadStatuses: Message[] = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
    private readonly workflowListComponentService: WorkflowListComponentService,
  ) {}

  ngOnInit(): void {
    this.url = this.workflowListComponentService.getWorkflowFileUploadUrl();
  }

  onError(): void {
    this.alertService.error(this.translate.instant('file-upload.cant-upload-file'));
  }

  onUpload(data: FileUploadEvent): void {
    const datasources = (data.originalEvent as HttpResponse<UploadWorkflowRequest>).body.datasources;
    this.uploadStatuses = datasources.map((datasource) => {
      const isSuccessStatus = [
        WorkflowDatasourceStatuses.CREATE_SUCCEED,
        WorkflowDatasourceStatuses.UPDATE_SUCCEED,
      ].includes(datasource.status);

      return {
        severity: isSuccessStatus ? 'success' : 'error',
        summary: `Datasource: ${datasource.datasourceName} (${datasource.datasourceId})`,
        detail: isSuccessStatus ? this.translate.instant('file-upload.cant-upload-file') : datasource.reason,
      };
    });
  }

  prepareRequest(formData: FormData) {
    formData.append('import_datasources', this.importDataSources.toString());
    formData.append('override_datasources', this.overrideDataSources.toString());
  }

  public changeImportingDatasourceCheckbox(): void {
    if (!this.importDataSources) {
      this.overrideDataSources = false;
    }
  }
}
