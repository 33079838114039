import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CodeEditorConfig } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'selfai-platform-create-data-code-editor',
  imports: [MonacoEditorModule, InputTextModule, FormsModule, TooltipModule, ButtonModule, TranslateModule],
  templateUrl: './create-data-code-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDataCodeEditorComponent implements OnInit {
  @Input({ required: true })
  model = '';

  @Output()
  modelChange = new EventEmitter<string>();

  editorOptions: CodeEditorConfig = {
    theme: 'sparksql',
    language: 'sql',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
  };

  private readonly dialogService = inject(DialogService<void, { model: string; modelChange: (value: string) => void }>);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    if (this.dialogService.data) {
      this.model = this.dialogService.data.model;
      this.modelChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        this.dialogService.data.modelChange(value);
      });
    }
  }
}
