import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Renderer2, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  CubeWorkflowData,
  ExternalSqlQueryFormGroup,
  ExternalSqlQueryParameters,
  ExternalSqlQueryRawParameters,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { MessageModule } from 'primeng/message';
import { CodeEditorComponentModule } from '../../../../code-editor';
import { EditSelectionFieldComponentModule } from '../../../../edit-selection-field/edit-selection-field.module';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { extractSelectionItemsExternalSqlQuery } from '../../../converters';
import { SelectionStoreService } from '../../../services';
import { CubeDialogManagementService } from '../../../services/cube-dialog-management.service';
import { DataSourceSelectorComponent } from '../../data-source-selector/data-source-selector.component';
import { DialogHeaderComponentModule } from '../../dialog-header';
import { DialogHeaderService } from '../../dialog-header/dialog-header.service';

@Component({
  selector: 'selfai-platform-external-sql-query',
  imports: [
    CommonModule,
    EditSelectionFieldComponentModule,
    ReactiveFormsModule,
    FormFieldComponentModule,
    DialogHeaderComponentModule,
    DataSourceSelectorComponent,
    TranslateModule,
    MessageModule,
    CodeEditorComponentModule,
  ],
  templateUrl: './external-sql-query.component.html',
  styleUrl: './external-sql-query.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogHeaderService],
})
export class ExternalSqlQueryComponent {
  get node(): GraphNode<ExternalSqlQueryRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): ExternalSqlQueryParameters {
    return this.normalizeRawParameters(this.node.parameters.serialize());
  }

  form = new FormGroup<ExternalSqlQueryFormGroup>({
    dataSourceId: new FormControl(null, [Validators.required]),
    column: new FormControl(null, [Validators.required]),
    sqlQuery: new FormControl(null, [Validators.required]),
  });

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<ExternalSqlQueryRawParameters>>,
    private readonly dialogHeaderService: DialogHeaderService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
    private readonly translateService: TranslateService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
    selectionStoreService: SelectionStoreService,
  ) {
    if (dialogService.data) {
      const {
        selectedNode: { parameters },
      } = dialogService.data;
      if (parameters) {
        const selectionColumns = extractSelectionItemsExternalSqlQuery(parameters);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }
  }

  ngOnInit(): void {
    this.form.patchValue(this.nodeParameters);
    this.dialogHeaderService.initCustomHeaderComponent(
      this.nodeId,
      this.translateService.instant('workflow.cubes.external-sql-query.modal-header'),
      this.onCloseDialog.bind(this),
      this.onSubmit.bind(this),
    );
    setTimeout(() => {
      this.cubeDialogManagementService.setFocus(this.nodeId);
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as ExternalSqlQueryParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  private normalizeRawParameters(params: ExternalSqlQueryRawParameters): ExternalSqlQueryParameters {
    return {
      dataSourceId: params['Choose JDBC Datasource'],
      // we don't support index type
      column:
        params['One of output columns'] && params['One of output columns'].type !== 'index'
          ? params['One of output columns'].value
          : null,
      sqlQuery: params['SQL query'],
    };
  }

  private normalizeFormValuesToApiModel(formValues: ExternalSqlQueryParameters): ExternalSqlQueryRawParameters {
    return {
      'Choose JDBC Datasource': formValues.dataSourceId,
      'One of output columns': formValues.column ? { type: 'column', value: formValues.column } : null,
      'SQL query': formValues.sqlQuery,
    };
  }
}
