import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CreateDataColumnType } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { CreateDataFrameComponentService } from '../create-data-frame-component.service';
import { CreateDataCodeEditorComponent } from './create-data-code-editor/create-data-code-editor.component';

type ColumnType = 'string' | 'number' | 'boolean' | 'other';

@Component({
  selector: 'selfai-platform-create-data',
  imports: [
    CommonModule,
    InputTextModule,
    InputSwitchModule,
    FormsModule,
    TableModule,
    TranslateModule,
    ButtonModule,
    TooltipModule,
    MessageModule,
    CreateDataCodeEditorComponent,
  ],
  templateUrl: './create-data.component.html',
  styleUrl: './create-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateDataComponent {
  items: Record<string, string | number | boolean>[] = [];

  get columns(): { name: string; id: string; type: ColumnType }[] {
    return this.createDataFrameComponentService.columns.map((column) => ({
      name: column.columnName,
      type: this.mapColumnType(column.columnType),
      id: column.id,
    }));
  }

  @Input({ required: true })
  set dataItems(value: Record<string, string | number | boolean>[]) {
    this.items = value;
  }
  @Output() dataItemsChange = new EventEmitter<Record<string, string | number | boolean>[]>();

  private readonly createDataFrameComponentService = inject(CreateDataFrameComponentService);
  private readonly dialogService = inject(DialogService<void, { model: string; modelChange: (value: string) => void }>);
  private readonly translate = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly cdr = inject(ChangeDetectorRef);

  onChange(index: number, key: string, value: string | number | boolean): void {
    this.items.forEach((item, i) => {
      if (i === index) {
        item[key] = value;
      }
    });
    this.dataItemsChange.emit(this.items);
  }

  removeItem(index: number): void {
    this.items = this.items.filter((_, i) => i !== index);
    this.dataItemsChange.emit(this.items);
  }

  copyItem(index: number): void {
    this.items = [...this.items, this.items[index]];
    this.dataItemsChange.emit(this.items);
  }

  addItem(): void {
    this.items = [...this.items, {}];
    this.dataItemsChange.emit(this.items);
  }

  mapColumnType(columnType: CreateDataColumnType | null): ColumnType {
    switch (columnType) {
      case CreateDataColumnType.Integer:
      case CreateDataColumnType.Float:
      case CreateDataColumnType.BigInteger:
      case CreateDataColumnType.Double:
      case CreateDataColumnType.Timestamp:
        return 'number';
      case CreateDataColumnType.Boolean:
        return 'boolean';
      case CreateDataColumnType.String:
        return 'string';
      default:
        return 'other';
    }
  }

  openCodeEditorDialog(index: number, columnName: string, currentValue: string): void {
    this.dialogService
      .showDialog(CreateDataCodeEditorComponent, {
        header: this.translate.instant('workflow.cubes.create-data-frame.code-editor.dialog-header'),
        width: '80%',
        height: '45vh',
        appendTo: 'body',
        closeOnEscape: true,
        closable: true,
        data: {
          model: currentValue,
          modelChange: (value: string) => {
            this.onChange(index, columnName, value);
          },
        },
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }
}
